<template>
  <div v-if="shouldShowAnything" :class="progressBarClasses">
    <RadialProgressBar v-if="shouldShowBar" :diameter="size"
                       :animate-speed="100"
                       :inner-stroke-width="innerStrokeWidth"
                       :stroke-width="strokeWidth"
                       :start-color="startColor"
                       :stop-color="stopColor"
                       :total-steps="target.max"
                       :completed-steps="target.val"
                       :is-clockwise="clockwise"
    >
      <p v-if="percent" v-text="computedPercent" class="percent"/>
    </RadialProgressBar>

    <template v-for="lr in ['left', 'right']">
      <template v-for="tb in ['top', 'bottom']">
        <span :key="tb + '-' + lr" v-if="hints[tb + '-' + lr] && dynamicValue(tb, lr)"
              :class="['hint', '-' + lr, '-' + tb]" v-text="dynamicValue(tb, lr)"
        :title="Game.commonLabels.hints[dynamicName(tb, lr)]"/>
      </template>
    </template>

    <Clock v-if="hasClock" :target="target" display="off" :text-updater="clockTextUpdater"/>
  </div>
</template>

<script setup>
import RadialProgressBar from 'vue3-radial-progress';
import Clock from '@/components/utils/Clock';
import {utils} from '@/helpers';
import {computed, ref} from 'vue';
import {Game} from '@/common';

const props = defineProps(
    {
      target: {type: Object},
      classes: {type: Array},
      showAtZero: {type: Boolean},
      alwaysShowHints: {type: Boolean},
      clockwise: {type: Boolean, default: true},
      startColor: {type: String, default: 'var(--bar-start)'},
      stopColor: {type: String, default: 'var(--bar-stop)'},
      percent: {type: [Boolean, Number], default: true},
      size: {type: Number, default: 80},
      strokeWidth: {type: Number, default: 9},
      innerStrokeWidth: {type: Number, default: 5},
      hints: {type: Object, default() { return {'top-right': 'clock', 'bottom-right': 'value'}; } },
    }
);

const clock = ref(null);

const maximum = computed(() => props.target?.max ?? props.target?.valB);

const shouldShowBar = computed(() => maximum.value && (props.showAtZero || props.target.val));

const shouldShowAnything = computed(() => shouldShowBar.value || props.alwaysShowHints);

const hasClock = computed(() => Object.values(props.hints).some(fn => fn === 'clock'));

const computedPercent = computed(() => {
  let value;
  if (typeof props.percent !== 'boolean') {
    value = props.percent;
  }
  else if (shouldShowBar.value) {
    value = props.target.val / maximum.value;
  }
  return value && (Math.floor(100 * value) + '%');
});

const progressBarClasses = computed(() => {
  const classes = ['rad-progress-bar'];
  props.classes && classes.push(...props.classes);
  return classes;
});

const hintColor = computed(() => props.startColor);

const dynamicMethods = {
  _value() {
    return utils.formatNumber(props.target.val);
  },

  _valueRemaining() {
    return utils.formatNumber(maximum.value - props.target.val);
  },

  _max() {
    return utils.formatNumber(maximum.value);
  },

  _valueOfMax() {
    return dynamicMethods._value() + '/' + dynamicMethods._max();
  },

  _clock() {
    return clock.value;
  },
};

function dynamicName(tb, lr) {
  return props.hints[tb + '-' + lr];
}

function dynamicValue(tb, lr) {
  return dynamicMethods['_' + dynamicName(tb, lr)]?.();
}

function clockTextUpdater(clockText) {
  clock.value = clockText;
}
</script>

<style>
.rad-progress-bar {
  position: absolute;
  top: 0;
  opacity: 0.75;
  width: 100%;
  height: 100%;
  text-align: center;
}

.rad-progress-bar .vrp__wrapper {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.rad-progress-bar .percent {
  color: var(--text-invert);
  background-color: var(--dark);
  padding: 1px 3px;
  border-radius: 4px;
  font-weight: bolder;
  font-size: 1.1em;
  opacity: 0.8;
}

.rad-progress-bar .hint {
  position: absolute;
  background-color: var(--dark);
  font-size: 0.85rem;
  padding: 1px;
  border-radius: 2px;
  color: v-bind(hintColor);
}

.rad-progress-bar .-left {
  left: 0.07rem;
}

.rad-progress-bar .-right {
  right: 0.15rem;
}

.rad-progress-bar .-bottom {
  bottom: 0.05rem;
}

.rad-progress-bar .-top {
  top: 0.05rem;
}

</style>