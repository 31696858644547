import {Game} from '@/common';
import {watch} from 'vue';

const MILLIS_IN_MINUTE = 60 * 1000;
const MILLIS_IN_HOUR = 60 * MILLIS_IN_MINUTE;
const MILLIS_IN_DAY = 24 * MILLIS_IN_HOUR;

/**
 * @type {Record<String, Intl.NumberFormat>}
 */
const NUMBER_FORMATS = {
  compact: Intl.NumberFormat('en', {maximumSignificantDigits: 3, notation: 'compact'}),
  compactSmall: Intl.NumberFormat('en', {maximumFractionDigits: 3, notation: 'compact'}),
  full: Intl.NumberFormat('en'),
}

function findActualValue(mutation, root) {
  if (mutation.sc === 'EN') {
    return root.props.energy.val;
  }
  else if (mutation.sc === 'ARTI') {
    return (root.artis[mutation.id] ?? {val: 0}).val;
  }
  else if (mutation.sc === 'PR') {
    return root.relations[mutation.id] ?? 0;
  }
  else if (mutation.sc === 'MODULE') {
    return Object.values(Game.ownedModulesRecords[root._id].modules).filter(
      m => (m.oid === mutation.id) &&
           (mutation.forged ? (m.state === 'on' || m.state === 'off') : true)
    ).length;
  }
  else {
    const steps = [];
    ['p1', 'p2', 'p3'].forEach(k => {
      const step = mutation[k];
      if (step !== undefined) {
        steps.push(step);
      }
    });
    const key = steps.pop();
    let parent = root;
    steps.forEach(step => parent = parent[step]);
    return parent[key];
  }
}

function canAfford(mutation, root) {
  if (mutation.permitted !== undefined) {
    return mutation.permitted;
  }
  const expected = mutation.val === undefined ? 1 : mutation.val;
  const actual = findActualValue(mutation, root);
  if (actual === undefined) {
    return mutation.op === '!=' ? !!expected : false;
  }
  switch (mutation.op) {
    case 'in':
      return expected.includes(actual);
    case '!=':
      return actual !== expected;
    case '<':
      return actual < expected;
    case '<=':
      return actual <= expected;
    case '>':
      return actual > expected;
    default:
      return actual >= expected;
  }
}

export const constants = {
  MILLIS_IN_MINUTE,
  MILLIS_IN_HOUR,
  MILLIS_IN_DAY,
  PROPERTY_CONFS: {
    level:      {                                         numberFormat: 'full' },
    decks:      { prop: 'props', color: 'var(--decks)',   numberFormat: 'full' },
    health:     { prop: 'props', color: 'var(--health)' },
    shields:    { prop: 'props', color: 'var(--shields)' },
    energy:     { prop: 'props', color: 'var(--energy)',  numberFormat: 'full' },
    cp:         { prop: 'props', color: 'var(--cp)' },
    rp:         { prop: 'props', color: 'var(--rp)' },
    mp:         { prop: 'props', color: 'var(--mp)' },
    attack:     { prop: 'props' },
    defence:    { prop: 'props' },
    speed:      { prop: 'props' },
    scan:       { prop: 'props' },
    cloak:      { prop: 'props' },
    engineers:  { prop: 'crew', color: 'var(--engineers)', numberFormat: 'full' },
  },
};

export const utils = {
  areEqualHolders(obj1, obj2) {
    if (obj1 === obj2) {
      return true;
    }
    if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return false;
    }
    return obj1.type === obj2.type && obj1.root?._id === obj2.root?._id;
  },

  canAfford(mutations, rootHolder) {
    return !rootHolder?.root || !mutations || mutations.every(m => canAfford(m, rootHolder.root));
  },

  millisToTime(millis, ceil = true) {
    const parts = [];
    if (ceil) {
      millis += 999; // to display eg 450ms as '1s'
    }

    const days = Math.trunc(millis / constants.MILLIS_IN_DAY);
    if (days) {
      millis -= days * constants.MILLIS_IN_DAY;
    }
    const hours = Math.trunc(millis / constants.MILLIS_IN_HOUR);
    if (hours) {
      parts.push(hours);
      millis -= hours * constants.MILLIS_IN_HOUR;
    }

    const minutes = Math.trunc(millis / constants.MILLIS_IN_MINUTE);
    if (hours || minutes) {
      parts.push(hours && minutes < 10 ? '0' + minutes : String(minutes));
      millis -= minutes * constants.MILLIS_IN_MINUTE;
    }

    let seconds = Math.trunc(millis / 1000);
    if (!hours && !minutes) {
      seconds = seconds + 's';
    }
    else if (seconds < 10) {
      seconds = '0' + seconds;
    }
    parts.push(seconds);

    const timePart = parts.join(':');
    return days ? [days, Game.labels.commons.units.days, timePart].join(' ') : timePart;
  },

  formatNumber(value, notation = 'compact') {
    if (typeof value !== 'number') {
      return value;
    }
    let keyword = notation;
    if (value > -0.01 && value < 0.01 && notation === 'compact') {
      keyword = 'compactSmall';
    }
    return NUMBER_FORMATS[keyword].format(value);
  },

  clone(object) {
    return object ? JSON.parse(JSON.stringify(object)) : {};
  },

  allKeysPresent(map, keys) {
    return keys.every(k => map[k]);
  },

  traverseSteps(root, steps) {
    let result = root;
    steps.forEach(p => result = result?.[p]);
    return result;
  },

  hasItems(array) {
    return !!array?.length;
  },

  regionOrAnsibleRequirement(root) {
    return !utils.hasTalent(Game.gameConf.talents.ansibleLink, root) &&
           Game.player.regionId !== root.regionId ?
           [{p1: 'regionId', op: 'in', val: [root.regionId]}] :
           [];
  },

  enhanceDealWithRegionOrAnsibleCheck(deal, root) {
    const requirement = utils.regionOrAnsibleRequirement(root);
    if (requirement.length) {
      !deal.requires && (deal.requires = []);
      deal.requires.push(...requirement);
    }
    return deal;
  },

  hasTalent(id, root = Game.player) {
    return !!root?.talents[id];
  },

  watchAndTrigger(source, callback) {
    watch(source, callback);
    callback();
  },

  sortBy(array, property, descending = false, numeric = false) {
    if (!array) {
      return array;
    }
    const getProperty = obj => numeric ? Number(obj[property]) : obj[property];
    const direction = descending ? -1 : 1;

    array.sort((a, b) => {
      const ap = getProperty(a);
      const bp = getProperty(b);
      if (ap > bp) return direction;
      if (ap < bp) return -direction;
      return 0;
    });
    return array;
  },

  removeExpiredEntries(record) {
    record && Object.values(record)
      .filter(o => o.to !== undefined && Game.now >= o.to)
      .forEach(o => delete record[o._id]);
  },
};

export const images = {
  tryRealPath(path) {
    let realPath = null;
    ['', '.jpg', '.png', '.svg'].some(suffix => {
      try {
        realPath = require('@/assets/' + path + suffix);
        return true;
      }
      catch (ignore) {
        return false;
      }
    });
    return realPath;
  },

  extractSingleMutationPath(mutations) {
    if (!mutations || mutations.length !== 1) {
      return null;
    }
    const mutation = mutations[0];
    switch (mutation.sc) {
      case 'MODULE':
        return `modules/${mutation.id}.jpg`;
      case 'ARTI':
        return `icons/artis/${mutation.id}.svg`;
      default:
        return null;
    }
  },

  getOrExtract(path, mutations) {
    if (images.tryRealPath(path)) {
      return path;
    }
    return images.extractSingleMutationPath(mutations) ?? path;
  },
};
