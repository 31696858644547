<template>
  <div class="-content-only">
    <DealButton v-if="showReassign" :action="reassign" :deal="{}" :root-holder="rootHolder"
                placeholder="configure" test-identifier="reassign">Reassign</DealButton>
    <DealButton v-if="isInProgress" :action="free" :deal="{gains: inProgressMutations}" :show-gains="true"
                :root-holder="rootHolder" test-identifier="stop">Stop</DealButton>
    <DealButton v-else :action="assign" :deal="enhancedDeal" :research-id="researchId" :mutex-infos="mutexInfos"
                :always-show-requires="true" :root-holder="rootHolder" test-identifier="start">{{buttonLabel}}</DealButton>
  </div>
</template>

<script setup>
import {utils} from '@/helpers';
import DealButton from '@/components/utils/DealButton';
import {api} from '@/api';
import {computed, toRef} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      apiPath: {type: String, required: true},
      assignmentId: {type: String, required: true},
      buttonLabel: {type: String, default: 'Start'},
      deal: {type: Object},
      researchId: {type: String},
      mutexInfos: {type: Array},
      rootHolder: {type: Object},
    },
);

const {
  assignedEngineersCount,
  totalEngineersCount,
  idleEngineersCount,
  hasIdleEngineers,
  inProgressMutations,
  isInProgress,
  getEngineersMutation,
} = compositions.assignments(props.assignmentId);

const rootHolder = compositions.rootOrPlayerHolder(props);

const { hasMutexesAtLimit } = compositions.mutexesAtLimit(toRef(() => props.mutexInfos));

const enhancedDeal = computed(() => {
  const result = utils.clone(props.deal);
  if (utils.canAfford(result.requires, rootHolder.value) &&
      utils.canAfford(result.costs, rootHolder.value) &&
      !hasMutexesAtLimit.value) {
    !result.requires && (result.requires = []);
    result.requires.push(getEngineersMutation(idleEngineersCount.value ?? 1, hasIdleEngineers.value));
  }
  return result;
});

const showReassign = computed(() => isInProgress.value && totalEngineersCount.value > 1);

function assign() {
  doPost();
}

function reassign() {
  const maxAvailable = idleEngineersCount.value + assignedEngineersCount.value;
  const count = window.prompt('How many should work?', maxAvailable);
  if (count === null) {
    return;
  }
  const toAdd = Number(count);
  if (toAdd || toAdd === 0) {
    doPost({count: toAdd});
  }
}

function free() {
  doPost({count: 0});
}

function doPost(body) {
  api.post(props.apiPath, body);
}
</script>
