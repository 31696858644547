<template>
  <Row :image-path="`modules/${id}.jpg`" :labels="labels" row-class="module-concept" title-display="full"
  :test-identifier="testId">
    <template v-if="!noValue" #totals>
      <div v-text="count"/>
    </template>

    <DealConcept :title="Game.commonLabels.deal.name" :deal="{gains: moduleObject.gains, costs: decksMutations}"
                 :root-holder="rootHolder" :mutex-infos="rootMutexInfos" test-identifier="deal"/>

    <DealConcept :title="Game.commonLabels.info.name" :deal="{}"
                 :research-id="moduleObject.researchId" :forging-val="moduleObject.forgingVal"
                 :root-holder="rootHolder" test-identifier="info"/>

    <DealConcept v-if="moduleObject.ability" :title="Game.commonLabels.ability.name" :deal="moduleObject.ability.deal"
                 :cooldown="moduleObject.ability.cooldown" :research-id="moduleObject.ability.researchId"
                 :root-holder="rootHolder" test-identifier="ability"/>

    <DealConcept :title="Game.commonLabels.shreds.name" :deal="{gains: moduleObject.shreds}"
                 :root-holder="rootHolder" :target-holder="playerHolder" test-identifier="shred"/>
  </Row>
</template>

<script setup>
import Row from '@/components/utils/Row';
import DealConcept from '@/components/utils/DealConcept';
import {Game, playerHolder} from '@/common';
import {computed, onMounted, toRef} from 'vue';
import {compositions} from '@/compositions';
import {api} from '@/api';

const props = defineProps(
    {
      id: {type: String, required: true},
      rootHolder: {type: Object},
      noValue: {type: Boolean},
    }
);

const ownerId = props.rootHolder?.root?._id;

onMounted(() => {
  if (ownerId && !modulesRecord.value) {
    api.loadModulesList(props.rootHolder.type, ownerId);
  }
});

const { moduleObject, labels } = compositions.module(props.id);

const { rootMutexInfos } = compositions.mux(moduleObject, toRef(() => props.rootHolder));

const { modulesRecord } = compositions.modules(ownerId);

const decksMutations = computed(() => [{p1: 'props', p2: 'decks', p3: 'val', val: moduleObject.value.size}]);

const count = computed(() => modulesRecord.value?.modules.filter(m => m.oid === props.id).length);

const testId = computed(() => `ModuleConcept::${props.id}`);
</script>

<style>
.module-concept .row-image {
  max-width: 95px;
}
</style>