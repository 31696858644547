import {utils} from '@/helpers';
import {Game} from '@/common';
import {compositions} from '@/compositions';

const SPECS = ['valB', 'max', 'maxB', 'uph', 'uphB'];
const DISPLAY_OPS = ['!=', '-', '<', '<=', '>', ':='];
const MAPPED_OPS = {'!=': 'not', ':=': '⤏'};

export class Displayer {
  _props;

  constructor(props) {
    this._props = props;
  }

  _defaultValue(notation) {
    const value = this._props.mutation.val;
    if (typeof value === 'boolean') {
      return null;
    }
    switch (this._props.mutation.op) {
      case '*':
        return (value >= 1 ? '+' : '') + Math.round((value - 1) * 100) + '%';
      case '/':
        return (value <= 1 ? '+' : '') + Math.round(100 / value - 100) + '%';
      case 'max':
        return null;
      default:
        return utils.formatNumber(value === undefined ? 1 : value, notation);
    }
  }

  _defaultOp() {
    const op = this._props.mutation.op;
    return DISPLAY_OPS.includes(op) ? (MAPPED_OPS[op] ?? op) : null;
  }

  _defaultOpAndValue(notation = undefined) {
    return [this._defaultOp(), this._defaultValue(notation)].filter(Boolean).join('');
  }

  _uniqueIcon(keyword, id) {
    return {
      path: `${keyword}/${id}`,
      title: Game.getLabels(keyword, id).name,
    };
  }

  _commonIcon(path) {
    return {
      path: path,
      title: Game.commonLabels[path].name,
    };
  }

  _shortcutConcept() {
    return {
      type: this._props.mutation.sc,
      id: this._props.mutation.id,
    };
  }
  
  _doIfSpec(callback) {
    const spec = this._props.mutation.p3;
    return SPECS.includes(spec) ? callback(spec) : null;
  }

  _parts() {
    switch (this._props.mutation.sc) {
      case 'EN':
        return ['props', 'energy'];
      case 'PR':
        return ['relations'];
      default:
        return [this._props.mutation.p1, this._props.mutation.p2].filter(Boolean);
    }
  }

  exists() {
    return true;
  }

  property() {
    const parts = this._parts();
    return {
      path: parts.join('/'),
      title: utils.traverseSteps(Game.commonLabels, parts).name,
    };
  }

  specification() {
    return this._doIfSpec(spec => this._commonIcon(spec));
  }

  values() {
    if (this._props.mutation.op === 'max') {
      return [this._commonIcon('toMax')];
    }
    else {
      const result = [
        {
          text: this._defaultOpAndValue(),
          title: this._defaultOpAndValue('full'),
        }
      ];
      if (this._props.mutation.duration) {
        const time = utils.millisToTime(this._props.mutation.duration * 1000);
        result.push(
          this._commonIcon('time'),
          {text: time, title: time}
        );
      }
      return result;
    }
  }

  allElements() {
    const isBoolean = typeof this._props.mutation.val === 'boolean';
    const elements = isBoolean ? [...this.values(), this.property()] : [this.property(), this.specification(), ...this.values()];
    return elements.filter(Boolean);
  }

  concepts() {
    const parts = this._parts();
    const result = [({parts: parts})];
    this._props.mutation.op === 'max' && result.push({parts: ['toMax']});
    this._doIfSpec(spec => result.push(({parts: [...parts, spec]})));
    return result;
  }
}

export class UniqueDisplayer extends Displayer {
  _keyword;

  constructor(props, keyword) {
    super(props);
    this._keyword = keyword;
  }

  property() {
    return this._uniqueIcon(this._keyword, this._props.mutation.p2);
  }
}

export class Research extends Displayer {
  _research;
  _computedDisplay;

  constructor(props, computedDisplayWrapped) {
    super(props);
    this._research = compositions.research(props.mutation.id);
    this._computedDisplay = computedDisplayWrapped;
  }

  exists() {
    return this._mustDisplay() || !this._researchFinished();
  }

  values() {
    const value = this._mustDisplay() ? this._research.researchObj.value?.val : this._research.researchLeft.value;
    return [{
      text: utils.formatNumber(value),
      title: utils.formatNumber(value, 'full')
    }];
  }

  concepts() {
    return this._researchFinished() ? [] : [{type: 'research', id: this._props.mutation.id}];
  }

  _mustDisplay() {
    return this._computedDisplay.value === 'info';
  }

  _researchFinished() {
    return this._research.researchProgressObj.value?.fin;
  }
}

export class Resource extends UniqueDisplayer {
  constructor(props) {
    super(props, 'resources');
  }

  concepts() {
    return [{type: this._keyword, parts: [this._keyword, this._props.mutation.p2]}];
  }
}

export class Arti extends Displayer {
  property() {
    return this._uniqueIcon('artis', this._props.mutation.id);
  }

  concepts() {
    return [this._shortcutConcept()];
  }
}

export class Module extends Displayer {
  property() {
    const icon = this._uniqueIcon('modules', this._props.mutation.id);
    icon.path = 'module';
    return icon;
  }

  specification() {
    return this._props.mutation.forged ? this._commonIcon('forged') : null;
  }

  concepts() {
    const result = [this._shortcutConcept()];
    this._props.mutation.forged && result.push({parts: ['forged']});
    return result;
  }
}

export class Relationship extends Displayer {
  specification() {
    return this._uniqueIcon('races', this._props.mutation.id);
  }

  values() {
    if (this._props.display === 'info') {
      return super.values();
    }
    return [this._commonIcon(this._getDirection())];
  }

  concepts() {
    const result = [this._shortcutConcept()];
    if (this._props.display !== 'info') {
      result.push({parts: [this._getDirection()]});
    }
    return result;
  }

  _getDirection() {
    const inc1 = (this._props.mutation.val ?? 1) > 0;
    const inc2 = (this._props.mutation.op ?? (this._props.display === 'gain' ? '+' : '-')) === '+';
    return inc1 === inc2 ? 'improve' : 'deteriorate';
  }
}

export class Region extends Displayer {
  values() {
    const elements = this.getRegionIds()
      .map(value => this._uniqueIcon('regions', value));
    const operation = this._defaultOp();
    operation && elements.unshift({text: operation});
    return elements;
  }

  concepts() {
    return this.getRegionIds().map(id => ({type: 'region', id: id}));
  }

  getRegionIds() {
    const val = this._props.mutation.val;
    return this._props.mutation.op === 'in' ? val : [val];
  }
}
