<template>
  <button type="button" :class="dealClasses" @click="action" :disabled="!canPerform" :data-testid="testId">
    <span v-if="showAnything" class="deal-button-mutations" data-testid="dealMutations">
      <span v-if="!canAffordTargetRequires" class="-targeted">
        <Mutations :list="targetDeal.requires" :root-holder="rootHolder" :target-holder="targetHolder" display="info"
                   :highlight-errors="true" :concept-display="conceptDisplay"/>
      </span>
      <Mutations v-if="computedShowRequires" :list="deal.requires" :progress="progress" :research-id="researchId"
                 :mutex-infos="mutexInfos" display="info" :highlight-errors="true" :concept-display="conceptDisplay"
                 :root-holder="rootHolder"/>
      <Mutations v-if="computedShowUses" :list="deal.uses" :display="usageDisplay" :concept-display="conceptDisplay"
                 :root-holder="rootHolder"/>
      <Mutations v-if="computedShowCosts" :list="deal.costs" display="attention"
                 :highlight-errors="true" :concept-display="conceptDisplay" :root-holder="rootHolder"/>
      <Mutations v-if="computedShowGains" :list="deal.gains" display="gain" :concept-display="conceptDisplay"
                 :root-holder="rootHolder"/>
      <Mutations v-if="computedShowTargetGains" :list="targetDeal.gains" display="gain" :concept-display="conceptDisplay"
                 :root-holder="rootHolder" :target-holder="targetHolder"/>
    </span>
    <span v-else-if="placeholder" class="deal-button-mutations" data-testid="dealMutations">
      <Mutations :list="[placeholderMutation]" :display="usageDisplay" :concept-display="conceptDisplay"/>
    </span>
    <span class="deal-content" data-testid="dealContent">
      <slot/>
    </span>
  </button>
</template>

<script setup>
import Mutations from '@/components/utils/Mutations';
import {utils} from '@/helpers';
import {Game} from '@/common';
import {computed, toRef} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      deal: {type: Object, required: true},
      targetDeal: {type: Object, default: () => ({})},
      rootHolder: {type: Object},
      targetHolder: {type: Object},
      progress: {type: Object},
      researchId: {type: String},
      mutexInfos: {type: Array},
      action: {type: Function, required: true},
      showGains: {type: Boolean, default: false},
      alwaysShowRequires: {type: Boolean, default: false},
      usageDisplay: {type: String, default: 'info'},
      placeholder: {type: [String, Object]},
      disabled: {type: Boolean, default: false},
      classes: {type: Array},
      ...compositions.propDefs.testId,
    }
);

const rootHolder = compositions.rootOrPlayerHolder(props);

const { isResearchNeeded } = compositions.research(props.researchId);

const { hasMutexesAtLimit } = compositions.mutexesAtLimit(toRef(() => props.mutexInfos));

const isInCooldown = computed(() => {
  const to = props.progress?.to;
  return to && (to > Game.now);
});

const preconditionsMet = computed(
    () => !props.disabled && !isInCooldown.value && !isResearchNeeded.value && !hasMutexesAtLimit.value);

const canAffordRequires = computed(() => utils.canAfford(props.deal.requires, rootHolder.value));

const computedShowRequires = computed(() => {
  if (!preconditionsMet.value) {
    return true;
  }
  return (props.alwaysShowRequires || !canAffordRequires.value) && utils.hasItems(props.deal.requires);
});

const computedShowUses = computed(() => utils.hasItems(props.deal.uses));

const computedShowCosts = computed(() => utils.hasItems(props.deal.costs));

const computedShowGains = computed(() => showGainsOf(props.deal));

const showAnything = computed(() => computedShowRequires.value ||
                                    computedShowUses.value ||
                                    computedShowCosts.value ||
                                    computedShowGains.value ||
                                    computedShowTargetGains.value);

const canAffordCosts = computed(() => utils.canAfford(props.deal.costs, rootHolder.value));

const computedShowTargetGains = computed(() => showGainsOf(props.targetDeal));

const canAffordTargetRequires = computed(() => utils.canAfford(props.targetDeal.requires, props.targetHolder));

const canAffordTargetCosts = computed(() => utils.canAfford(props.targetDeal.costs, props.targetHolder));

const canPerform = computed(
    () => preconditionsMet.value &&
          canAffordCosts.value &&
          canAffordRequires.value &&
          canAffordTargetRequires.value &&
          canAffordTargetCosts.value);

const conceptDisplay = computed(() => canPerform.value ? 'off' : 'full');

const dealClasses = computed(() => {
  const result = ['deal'];
  props.classes && result.push(...props.classes);
  return result;
});

const placeholderMutation = computed(() => typeof props.placeholder === 'string' ?
                                           ({p1: props.placeholder, val: ''}) :
                                           props.placeholder);

const testId = computed(() => `DealButton::${props.testIdentifier}`);

function showGainsOf(deal) {
  return props.showGains && !isInCooldown.value && utils.hasItems(deal.gains);
}
</script>

<style>
.deal-button-mutations {
  justify-content: center;
  height: 18px;
  margin-bottom: 2px;
  font-weight: normal;
}

@media screen and (max-width: 540px) {
  .-compact .deal-button-mutations {
    display: flex;
  }
}

@media screen and (max-width: 300px) {
  .deal-button-mutations {
    display: flex;
  }
}
</style>